import webDesignLogo from './assets/ghibliwebsitecropped.png';
import bookCoversLogo from './assets/Book-Cover_A-Collection-of-Poems_1.png';
import logosLogo from './assets/BGH-haven-family-counseling-logo-1.png';
import mobileLogo from './assets/Via_Design_Logo_Large_Full.png';
import {Link} from 'react-router-dom';
import './App.css';



function App() {
  return (
      
    <div className="container main-container">
      <div className='row d-block d-md-none mb-5'>
        <img className='w-100 text-center' src={mobileLogo} />
      </div>
      <div className="row">
        <Link to={'portfolio/web'} className="col-12 col-md-9 category-link"><div className="category-logo-container mt-1"><div className="category-logo-text category-logo-text-large">Web Design</div><img className="category-logo" src={webDesignLogo}/></div></Link>
        <div className="col-12 col-md-3">
          <div className="row">
            <Link to={'portfolio/books'} className="category-link"><div className="category-logo-container mt-1"><div className="category-logo-text category-logo-text-small">Book Covers</div><img className="category-logo category-logo-small" src={bookCoversLogo}/></div></Link>
          </div>
          <div className="row pt-2">
            <Link to={'portfolio/logos'} className="category-link"><div className="category-logo-container mt-1"><div className="category-logo-text category-logo-text-small">Logos</div><img className="category-logo category-logo-small" src={logosLogo}/></div></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
