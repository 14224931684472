import kristen from './assets/kristen.jpg';
import './Contact.css';

function Contact(){
    return (
    <div className="container main-container">
        <div className="row">
            <p className="col-12 col-md-8 text-center my-auto contact-blurb">
            Kristen Via is a graphic designer living in Mt. Washington, KY. You can contact her at kristenviadat@gmail.com
            </p>
            <img className="col-12 col-md-4" src={kristen}/>
        </div>
    </div>
        );
}

export default Contact;