import './BookCovers.css';
import poems1 from '../assets/Book-Cover_A-Collection-of-Poems_1.png';
import poems2 from '../assets/Book-Cover_A-Collection-of-Poems_2.png';
import program1 from '../assets/Book-Cover_The-Program_1.png';
import program2 from '../assets/Book-Cover_The-Program_2.png';
import tm1 from '../assets/Book-Cover_The-Text-Messages_1.png';
import tm2 from '../assets/Book-Cover_The-Text-Messages_2-(Final).png';
import tu1 from '../assets/Book-Cover_The-Unknown_1.png';
import tu2 from '../assets/Book-Cover_The-Unknown_2.png';
import wildflowers1 from '../assets/Book-Cover_Where-the-Wildflowers-Grow_1-(Final).png';
import wildflowers2 from '../assets/Book-Cover_Where-the-Wildflowers-Grow_2-(Final).png';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import React from 'react';
import ScrollToTop from '../ScrollToTop';


function BookCovers() {
    const [index, setIndex] = React.useState(0);
    const [modalOpen, setModalOpen] = React.useState(false);
    const slides = [{
        src: poems1
    },
    {
        src: poems2
    },
    {
        src: program1
    },
    {
        src: program2
    },
    {
        src: tm1
    },
    {
        src: tm2
    },
    {
        src: tu1
    },
    {
        src: tu2
    },
    {
        src: wildflowers1
    },
    {
        src: wildflowers2
    }];

    return (
        <div className='main-container container'>
            <ScrollToTop/>
            <Lightbox index={index} open={modalOpen} close={() => setModalOpen(false)} slides={slides} />
            <div className='row'>
                <h1 className='text-center'>Book Covers</h1>
            </div>
            <div className='row'>
                <a href={poems1}  onClick={(e) => {e.preventDefault(); setIndex(0); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={poems1}></img></a>
                <a href={poems2} onClick={(e) => {e.preventDefault(); setIndex(1); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={poems2}></img></a>
                <a href={program1} onClick={(e) => {e.preventDefault(); setIndex(2); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={program1}></img></a>
                <a href={program2} onClick={(e) => {e.preventDefault(); setIndex(3); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={program2}></img></a>
                <a href={tm1} onClick={(e) => {e.preventDefault(); setIndex(4); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={tm1}></img></a>
                <a href={tm2} onClick={(e) => {e.preventDefault(); setIndex(5); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={tm2}></img></a>
                <a href={tu1} onClick={(e) => {e.preventDefault(); setIndex(6); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={tu1}></img></a>
                <a href={tu2} onClick={(e) => {e.preventDefault(); setIndex(7); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={tu2}></img></a>
                <a href={wildflowers1} onClick={(e) => {e.preventDefault(); setIndex(8); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={wildflowers1}></img></a>
                <a href={wildflowers2} onClick={(e) => {e.preventDefault(); setIndex(9); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={wildflowers2}></img></a>
            </div>
        </div>
    )
}

export default BookCovers;