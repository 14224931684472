import './Logos.css';
import bgh1 from '../assets/logos/BGH-bgh-design_C.png';
import bghFamilyCounseling1 from '../assets/logos/BGH-haven-family-counseling-logo_C.png';
import bghFamilyCounseling2 from '../assets/logos/BGH-haven-family-counseling-logo-2_c.png';
import bghIndReadiness1 from '../assets/logos/BGH-independence-readiness-logo-1.png';
import bghIndReadiness2 from '../assets/logos/BGH-independence-readiness-logo-2.png';
import smth1 from '../assets/logos/smth-and-bull-honey_1.png';
import smth2 from '../assets/logos/smth-and-bull-honey_2.png';
import smth3 from '../assets/logos/smth-and-bull-honey_3.png';
import bunny1 from '../assets/logos/The-Bunny-Cafe_Background_C.png'
import bunny2 from '../assets/logos/The-Bunny-Cafe_Logo_Circle_C.png'
import bunny3 from '../assets/logos/The-Bunny-Cafe_Logo_Full_Background.png'
import bunny4 from '../assets/logos/The-Bunny-Cafe_Logo_Full_C.png'
import bunny5 from '../assets/logos/The-Bunny-Cafe_Logo_Transparent_C.png'
import bunny6 from '../assets/logos/The-Bunny-Cafe_Logo_2_C.png'
import bunny7 from '../assets/logos/The-Bunny-Cafe_Logo_3_C.png'
import kpg1 from '../assets/logos/Kairos_Pen_Games_card.png';
import kpg2 from '../assets/logos/Kairos_Pen_Games_logo.png';
import bghSticker1 from '../assets/logos/BGH-lavender-sticker.png';
import bghSticker2 from '../assets/logos/BGH-lavender-sticker.png';
import coke1 from '../assets/logos/Coke-tab-accessories-advertisement_1_fake.png';
import coke2 from '../assets/logos/Coke-tab-accessories-advertisement_2_fake.png';
import ablaze1 from '../assets/logos/Ablaze_Logo_Option_1.png';
import ablaze2 from '../assets/logos/Ablaze_Logo_Option_2.png';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import React from 'react';
import ScrollToTop from '../ScrollToTop';


function Logos() {
    const [index, setIndex] = React.useState(0);
    const [modalOpen, setModalOpen] = React.useState(false);
    const slides = [
    {
        src: bgh1
    },
    {
        src: bghFamilyCounseling1
    },
    {
        src: bghFamilyCounseling2
    },
    {
        src: bghIndReadiness1
    },
    {
        src: bghIndReadiness2
    },
    {
        src: smth1
    },
    {
        src: smth2
    },
    {
        src: smth3
    },
    {
        src: bunny1
    },
    {
        src: bunny2
    },
    {
        src: bunny3
    },
    {
        src: bunny4
    },
    {
        src: bunny5
    },
    {
        src: bunny6
    },
    {
        src: bunny7
    },
    {
        src: kpg1
    },
    {
        src: kpg2
    },
    {
        src: bghSticker1
    },
    {
        src: bghSticker2
    },
    {
        src: coke1
    },
    {
        src: coke2
    },
    {
        src: ablaze1
    },
    {
        src: ablaze2
    }];

    return (
        <div className='main-container container'>
            <ScrollToTop/>
            <Lightbox index={index} open={modalOpen} close={() => setModalOpen(false)} slides={slides} />
            <div className='row'>
                <h1 className='text-center'>Logos</h1>
            </div>
            <div className='row'>
                <a href={bgh1}  onClick={(e) => {e.preventDefault(); setIndex(0); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bgh1}></img></a>
                <a href={bghFamilyCounseling1}  onClick={(e) => {e.preventDefault(); setIndex(1); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bghFamilyCounseling1}></img></a>
                <a href={bghFamilyCounseling2}  onClick={(e) => {e.preventDefault(); setIndex(2); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bghFamilyCounseling2}></img></a>
                <a href={bghIndReadiness1}  onClick={(e) => {e.preventDefault(); setIndex(3); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bghIndReadiness1}></img></a>
                <a href={bghIndReadiness2}  onClick={(e) => {e.preventDefault(); setIndex(4); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bghIndReadiness2}></img></a>
                <a href={smth1}  onClick={(e) => {e.preventDefault(); setIndex(5); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={smth1}></img></a>
                <a href={smth2}  onClick={(e) => {e.preventDefault(); setIndex(6); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={smth2}></img></a>
                <a href={smth3}  onClick={(e) => {e.preventDefault(); setIndex(7); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={smth3}></img></a>
                <a href={bunny1}  onClick={(e) => {e.preventDefault(); setIndex(8); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bunny1}></img></a>
                <a href={bunny2}  onClick={(e) => {e.preventDefault(); setIndex(9); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bunny2}></img></a>
                <a href={bunny3}  onClick={(e) => {e.preventDefault(); setIndex(10); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bunny3}></img></a>
                <a href={bunny4}  onClick={(e) => {e.preventDefault(); setIndex(11); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bunny4}></img></a>
                <a href={bunny5}  onClick={(e) => {e.preventDefault(); setIndex(12); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bunny5}></img></a>
                <a href={bunny6}  onClick={(e) => {e.preventDefault(); setIndex(13); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bunny6}></img></a>
                <a href={bunny7}  onClick={(e) => {e.preventDefault(); setIndex(14); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bunny7}></img></a>
                <a href={kpg1}  onClick={(e) => {e.preventDefault(); setIndex(15); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={kpg1}></img></a>
                <a href={kpg2}  onClick={(e) => {e.preventDefault(); setIndex(16); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={kpg2}></img></a>
                <a href={bghSticker1}  onClick={(e) => {e.preventDefault(); setIndex(17); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bghSticker1}></img></a>
                <a href={bghSticker2}  onClick={(e) => {e.preventDefault(); setIndex(18); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={bghSticker2}></img></a>
                <a href={coke1}  onClick={(e) => {e.preventDefault(); setIndex(19); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={coke1}></img></a>
                <a href={coke2}  onClick={(e) => {e.preventDefault(); setIndex(20); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={coke2}></img></a>
                <a href={ablaze1}  onClick={(e) => {e.preventDefault(); setIndex(21); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={ablaze1}></img></a>
                <a href={ablaze2}  onClick={(e) => {e.preventDefault(); setIndex(22); setModalOpen(true);}} className='image-container col-6 col-md-4'><img className='portfolio-image mt-3' src={ablaze2}></img></a>
            </div>
        </div>
    )
}

export default Logos;