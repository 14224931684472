import {Link} from 'react-router-dom';
import logo from './assets/Via_Design_Logo_Medium_Grey.png';
import smallLogo from './assets/Via_Design_Logo_Small_Condensed.png';
import { useLocation } from 'react-router-dom';
import React from 'react';

function Navbar(){
    const location = useLocation();
    const [scrollTop, setScrollTop] = React.useState(document.documentElement.scrollTop);
    
    window.onscroll = function() {
        setScrollTop(document.documentElement.scrollTop);
    }

    function collapseNavbar(){
        if(window.bootstrap){
            window.bootstrap.Collapse.getOrCreateInstance(document.getElementById('navbarNavSmall'), {toggle: false}).hide()
        }
    }

    return (
    <>
      <div className={'absoluteBar ' + (scrollTop < 100 ? 'd-none d-md-block' : 'd-none')}></div>
      <nav className={'navbar bigNavbar navbar-expand-lg navbar-dark ' + (scrollTop < 100 ? '' : 'd-none')}>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto align-items-center">
            <li className="nav-item big-nav-item dropdown">
              <a className={'nav-link portfolio-link dropdown-toggle ' + (location.pathname.startsWith('/portfolio') ? 'isActive' : '')}  data-bs-toggle="dropdown" href="#">PORTFOLIO</a>
              <div className="dropdown-menu">
                <Link to={'/portfolio/books'} className="dropdown-item">Book Covers</Link>
                <div className="dropdown-divider"></div>
                <Link to={'/portfolio/logos'} className="dropdown-item">Logos</Link>
                <div className="dropdown-divider"></div>
                <Link to={'/portfolio/web'} href="#" className="dropdown-item">Web Design</Link>
              </div>
            </li>
            <Link to={'/'}><img className="brand-logo" src={logo}/></Link>
            <li className="nav-item big-nav-item">
              <Link to={'/contact'} className={'nav-link contact-link ' + (location.pathname.startsWith('/contact') ? 'isActive' : '')} href="#">CONTACT&nbsp;&nbsp;&nbsp;&nbsp;</Link>
            </li>
          </ul>
        </div>
      </nav>
        <nav className={'navbar fixed-top navbar-expand-lg navbar-dark bg-dark small-navbar ' + (scrollTop >= 100 ? '' : 'd-md-none') }>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavSmall" aria-controls="navbarNavSmall" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <Link to={'/'} className='float-left text-center small-navbar-logo'><img className='brand-logo brand-logo-small' src={smallLogo}/></Link>

            <div className='collapse navbar-collapse' id="navbarNavSmall">
                <ul className='navbar-nav'>
                    <li className='nav-item small-nav-item dropdown'>
                        <a className={'nav-link dropdown-toggle ' + (location.pathname.startsWith('/portfolio') ? 'isActive' : '')}  data-bs-toggle="dropdown" href="#">PORTFOLIO</a>
                        <div className="dropdown-menu">
                        <Link to={'/portfolio/books'} className="dropdown-item" onClick={collapseNavbar}>Book Covers</Link>
                        <div className="dropdown-divider"></div>
                        <Link to={'/portfolio/logos'} className="dropdown-item" onClick={collapseNavbar}>Logos</Link>
                        <div className="dropdown-divider"></div>
                        <Link to={'/portfolio/web'} href="#" className="dropdown-item" onClick={collapseNavbar}>Web Design</Link>
                        </div>
                    </li>
                    <li className="nav-item small-nav-item">
                    <Link to={'/contact'} className={'nav-link ' + (location.pathname.startsWith('/contact') ? 'isActive' : '')} href="#" onClick={collapseNavbar}>CONTACT&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                    </li>
                </ul>
            </div>
        </nav>
    </>
    );
}

export default Navbar;