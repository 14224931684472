import './WebDesign.css';
import ghibli from '../assets/Ghibli in the park_Fake advertisement website.png';
import ghibliCropped from '../assets/ghibliwebsitecropped.png';
import croissant from '../assets/croissant.png';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import React from 'react';
import ScrollToTop from '../ScrollToTop';


function WebDesign() {
    const [index, setIndex] = React.useState(0);
    const [modalOpen, setModalOpen] = React.useState(false);
    const slides = [{
        src: ghibli
    },
    {
        src: croissant
    }];

    return (
        <div className='main-container container'>
            <ScrollToTop/>
            <Lightbox index={index} open={modalOpen} close={() => setModalOpen(false)} slides={slides} />
            <div className='row'>
                <h1 className='text-center'>Web Designs</h1>
            </div>
            <div className='row mt-3'>
                <a href={ghibli}  onClick={(e) => {e.preventDefault(); setIndex(0); setModalOpen(true);}} className='image-container col-12 col-md-6'><img className='portfolio-image mt-1' src={ghibliCropped}></img></a>
                <a href={croissant} onClick={(e) => {e.preventDefault(); setIndex(1); setModalOpen(true);}} className='image-container col-12 col-md-6'><img className='portfolio-image mt-1' src={croissant}></img></a>
            </div>
        </div>
    )
}

export default WebDesign;